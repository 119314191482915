import React from "react";
import { Link, graphql } from "gatsby";
import Bayout from "../../components/blogLayout";

export default ({ data }) => {
  const post = data.allWordpressPost.edges[0].node;
  const featuredImage = (post.featured_media)
    ? <img src={post.featured_media.source_url} alt="" className="img-fluid mb-4 mb-md-5" />
    : "";

  return (
    <Bayout>
      <div className="blog-post">
        <div className="blog-post-title text-inter mb-3" dangerouslySetInnerHTML={{ __html: post.title }} />
        <div className="blog-post-details text-ptmono d-flex mb-4 mb-md-5">
          <div className="text-uppercase mr-3 mr-md-5">
            <span>By </span>
            <Link to={'/blog/author/scott-mcdonald'}>Scott McDonald</Link>
          </div>
          <div className="text-muted">{post.date}</div>
        </div>
        {featuredImage}
        <div className="entry-content" dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
    </Bayout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWordpressPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          date(formatString: "YYYY/MM/DD")
          featured_media {
            source_url
         }
        }
      }
    }
  }
`
